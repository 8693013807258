<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="customs" v-model:filters="filters1" v-model:selection="selectedRow"
                    selectionMode="multiple" :metaKeySelection="false" :filters="filters1" filterDisplay="menu"
                    dataKey="id" :globalFilterFields="['name','code']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :lazy="true" :loading="loading" :paginator="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="清除" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:25%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入客户名称或代码模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.simplename" @click="openCustom(slotProps.data)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :pageJsonConfig="pageJsonConfig" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="共享客户" v-model:visible="displayShare" :style="{width: '40vw'}" :maximizable="false" :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfMultiSelect id="shareywy" :loadAll="true" v-model:selectedValue="selectedShareUser"
                        dictObjName="usernoselfdict" />
                    <label for="shareywy">共享给业务员</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeShare" />
                <Button label="确认" icon="pi pi-check" @click="saveShare" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="共享类别" v-model:visible="displayShareCategory" :style="{width: '40vw'}" :maximizable="false"
        :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfDropdown id="shareOwnerUserId" :loadAll="true" v-model:selectedValue="selectedShareOwnerUser"
                        dictObjName="userdict" />
                    <label for="shareOwnerUserId">业务员的客户</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfMultiSelect id="shareywyCategory" :loadAll="true"
                        v-model:selectedValue="selectedShareCategoryUser" dictObjName="usernoselfdict" />
                    <label for="shareywyCategory">共享给业务员</label>
                </span>
            </div>
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfMultiSelect id="sharectypeCategory" v-model:selectedValue="selectedShareCustomType"
                        dictObjName="dictionary" :loadAll="true" dictCategories="CustomType" />
                    <label for="sharectype">共享类型</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeShare" />
                <Button label="确认" icon="pi pi-check" @click="saveShareCategory" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="上传导入数据" v-model:visible="importDisplay" :style="{width: '30vw'}" :maximizable="true" :modal="true">
        <div class="grid p-fluid mt-3">
            <div v-if="!startImport" class="field col-12 md:col-12">
                <Dropdown id="importtemplateFileType" v-model="importTemplateId" :options="excelTemplate"
                    optionLabel="name" optionValue="code" placeholder="请选择导入类型" />
            </div>
            <div v-if="!startImport" class="field col-12 md:col-12">
                <Button label="导入模板下载" @click="getTemplateFilePatch" class="p-button-link" />
            </div>
            <div v-if="!startImport" class="field col-12 md:col-12">
                <FileUpload mode="basic" id="importtemplateFileId" style="width:100%" name="importtemplateFile"
                    :url="uploadurl" accept=".xlsx" :maxFileSize="20971520" @upload="onImportUpload"
                    @before-upload="beforeImportUpload" :auto="true" invalidFileSizeMessage="{0}: 文件大小必须小于20M."
                    invalidFileLimitMessage="超过文件上传个数,一次只能上传 {0}个文件" chooseLabel="请选择上传EXCEL文件" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startImport" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeImport" />
            </div>
        </template>
    </Dialog>
    <Dialog header="转移客户" v-model:visible="displayTransfer" :style="{width: '40vw'}" :maximizable="false" :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <span class="p-float-label">
                    <CalfInputDropdown v-model:selectedValue="selectedTransferUser" id="transferywy"
                        dictObjName="usernoselfdict" :loadAll="true" :canEditable="false" />
                    <label for="transferywy">转移给业务员</label>
                </span>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="取消" icon="pi pi-power-off" class="ml-auto" @click="closeTransfer" />
                <Button label="确认" icon="pi pi-check" @click="saveTransfer" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="客户共享详情" v-model:visible="displayShareList" :style="{width: '60vw'}" :maximizable="false"
        :modal="true">
        <DataTable :value="sharedata" v-model:filters="filters2" v-model:selection="selectedShareRow"
            selectionMode="single" :metaKeySelection="false" filterDisplay="menu" dataKey="id"
            :globalFilterFields="['name','sharetypename','sharetouser']" scrollable scrollHeight="380px"
            responsiveLayout="scroll" :lazy="true" :loading="loading" :paginator="true" rows="50"
            :totalRecords="sharetotalRecords" :first="sharefirst"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
            @page="sharelistPage($event)">
            <Column field="name" style="min-width:200px" :showFilterOperator="false" header="客户名称/类型" frozen
                :filterMatchModeOptions="matchModes">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();" class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="sharetouser" style="min-width:200px" :showFilterOperator="false" header="共享人" frozen
                :filterMatchModeOptions="matchModes">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();" class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="sharetypename" style="min-width:200px" :showFilterOperator="false" header="共享类型" frozen
                :filterMatchModeOptions="matchModes">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();" class="p-button-success"></Button>
                </template>
            </Column>
        </DataTable>
    </Dialog>
</template>

<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import CalfMultiSelect from '../../../components/CalfMultiSelect.vue';
    import CalfInputDropdown from '../../../components/CalfInputDropdown.vue';
    import CalfDropdown from '../../../components/CalfDropdown.vue';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import customPageJson from "@/data/customConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '687';
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                uploadurl: '',
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                excelDatas: [],
                startImport: false,
                importProgress: '',
                importProgressValue: 0,
                butLoading: false,
                recordsubject: '',
                readOnly: false,
                displayShareList: false,
                displayQueryPlan: false,
                displayMaximizable: false,
                displayShare: false,
                displayTransfer: false,
                importDisplay: false,
                displayShareCategory: false,
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filters2: null,
                currentRow: {
                    bindpage: 'crmcustom',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                selectedRow: ref(),
                selectedShareRow: ref(),
                selectedShareUser: ref(),
                selectedShareCustomType: ref(),
                selectedTransferUser: ref(),
                selectedShareCategoryUser: ref(),
                selectedShareOwnerUser: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                sharetotalRecords: 0,
                sharefirst: 0,
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '我的客户列表',
                selectedKey: {
                    687: true
                },
                expandedKey: null,
                customs: [],
                sharedata: [],
                loading: true,
                nodes: [],
                items: [{
                    label: '客户操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '导入客户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.importCustom();
                            }
                        },
                        {
                            separator: true
                        }, {
                            label: '新建客户',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createCustom();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '共享客户',
                            icon: 'pi pi-fw pi-user-edit',
                            command: () => {
                                this.sharCustom();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '查看共享情况',
                            icon: 'pi pi-fw pi-user-edit',
                            command: () => {
                                this.querysharCustom();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '转移客户',
                            icon: 'pi pi-fw pi-user-minus',
                            command: () => {
                                this.transferCustom();
                            }
                        }, {
                            separator: true
                        },
                        {
                            label: '公开客户',
                            icon: 'pi pi-fw pi-user-minus',
                            command: () => {
                                this.publicCustom();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                excelTemplate: [{
                    name: '客户导入',
                    code: '178228300017733'
                }, {
                    name: '客户联系人导入',
                    code: '178710117163077'
                }],
                importTemplateId: null,
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getCustom(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.initFilters2();
                    this.loadMenuTree();
                    this.loadUserAuth();
                    this.importProgress = '';
                    this.startImport = false;
                    this.importProgressValue = 0;
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    console.log(JSON.stringify(this.listcolumns));
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            initFilters2() {
                this.filters2 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'sharetouser': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS,
                        }]
                    },
                    'sharetypename': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                };
            },
            clearFilter2() {
                this.initFilters2();
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '682',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;
                        for (var i = 0; i < this.usermoduleauth.length; i++) {
                            if (this.usermoduleauth[i].appid == 'CrmShareCustomCategory' && this.usermoduleauth[
                                    i]
                                .own) {
                                this.items[0]['items'].splice(6, 0, {
                                    label: '类别共享',
                                    icon: 'pi pi-fw pi-user-edit',
                                    command: () => {
                                        this.sharCustomByCategory();
                                    }
                                }, {
                                    separator: true
                                });
                                break;
                            }
                        }

                        var createcustomflag = this.$route.query['createcustom'];
                        if (createcustomflag) {
                            this.createCustom();
                        }
                    }
                });
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('682', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '我的客户列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'mycustom',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'name,code',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{crmcustomlist(where:" + JSON.stringify(listwhere) +
                    "){id simplename name address ownername ownerid city code country countryname customtype customtypename firstordertime mainchannel tel wechat qq remark}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.customs = jsonData.data.crmcustomlist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            sharelistPage(event) {
                var page = event.page;
                this.loadShareData(page + 1);
            },
            loadShareData(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: 50,
                    conditions: []
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{customsharelist(where:" + JSON.stringify(listwhere) +
                    "){id name sharetouser sharetypename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.sharedata = jsonData.data.customsharelist;
                        this.sharetotalRecords = jsonData.page.allrecord;
                        this.sharefirst = 50 * (jsonData.page.pageindex - 1);
                    }
                });
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            sharCustom() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要共享的客户');
                    return;
                }
                this.displayShare = true;
            },
            sharCustomByCategory() {
                this.displayShareCategory = true;
            },
            querysharCustom() {
                this.displayShareList = true;
                this.loadShareData(1);
            },
            transferCustom() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要转移的客户');
                    return;
                }
                this.displayTransfer = true;
            },
            importCustom() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateCustom')) {
                    MessageTip.warnmsg('请联系管理员开通新建客户权限');
                    return;
                }
                this.importProgress = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.excelDatas = [];
                this.importDisplay = true;
            },
            publicCustom() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要公开的客户');
                    return;
                }
                var customPublic = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    customPublic.push({
                        "id": this.selectedRow[k1]["id"],
                        "ownerid": '-1',
                        "ownername": '',
                        "changehistory": [{
                            "id": "-1",
                            "changetype": 2,
                            "changetime": "",
                            "changeuserid": sessionStorage.getItem("userid"),
                            "parentid": this.selectedRow[k1]["id"],
                        }]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{crmcustomlist(o:" + JSON.stringify(customPublic) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('公开成功');
                    } else {
                        MessageTip.warnmsg('公开失败');
                    }
                });
            },
            createCustom() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateCustom')) {
                    MessageTip.warnmsg('请联系管理员开通新建客户权限');
                    return;
                }
                this.recordsubject = '创建我的客户';
                this.currentRow = {
                    bindpage: 'crmcustom',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            openCustom(v_data) {
                this.recordsubject = v_data.name;
                this.currentRow = {
                    bindpage: 'crmcustom',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                };
                this.displayMaximizable = true;
            },
            closeShare() {
                this.displayShare = false;
                this.displayShareCategory = false;
            },
            saveShare() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要共享客户');
                    return;
                }
                if (!this.selectedShareUser || this.selectedShareUser.length == 0) {
                    MessageTip.warnmsg('请选择共享人员');
                    return;
                }
                var customShareByRecord = [];
                if (this.selectedRow && this.selectedRow.length > 0 && this.selectedShareUser && this.selectedShareUser
                    .length > 0) {
                    for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                        for (var k2 = 0; k2 < this.selectedShareUser.length; k2++) {
                            customShareByRecord.push({
                                "sharetype": "1",
                                "sharerecordid": this.selectedRow[k1]["id"],
                                "sharetouserid": this.selectedShareUser[k2]["code"],
                                "shareowneruserid": -1,
                            });
                        }
                    }
                }
                if (customShareByRecord.length > 0) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "mutation{customsharelist(o:" + JSON.stringify(customShareByRecord) +
                        "){id errmsg}}"
                    ).then(res => {
                        this.butLoading = false;
                        if (res.errcode == "0") {
                            this.selectedRow = null;
                            this.selectedShareCustomType = null;
                            this.selectedShareUser = null;
                            MessageTip.successmsg('共享成功');
                        } else {
                            MessageTip.warnmsg('共享失败');
                        }
                        this.closeShare();
                    });
                }
            },
            saveShareCategory() {
                if (!this.selectedShareOwnerUser || parseInt(this.selectedShareOwnerUser) <= 0) {
                    MessageTip.warnmsg('请选择要共享客户的业务员');
                    return;
                }
                if (!this.selectedShareCustomType || this.selectedShareCustomType && this.selectedShareCustomType
                    .length == 0 || !this
                    .selectedShareCategoryUser || this
                    .selectedShareCategoryUser &&
                    this.selectedShareCategoryUser.length == 0) {
                    MessageTip.warnmsg('请选择共享人员和共享客户类型');
                    return;
                }
                var customShareByRecord = [];
                if (this.selectedShareCustomType && this.selectedShareCustomType.length > 0 && this
                    .selectedShareCategoryUser &&
                    this.selectedShareCategoryUser.length > 0) {
                    for (var k3 = 0; k3 < this.selectedShareCustomType.length; k3++) {
                        for (var k4 = 0; k4 < this.selectedShareCategoryUser.length; k4++) {
                            customShareByRecord.push({
                                "sharetype": "2",
                                "sharerecordid": this.selectedShareCustomType[k3]["code"],
                                "sharetouserid": this.selectedShareCategoryUser[k4]["code"],
                                "shareowneruserid": this.selectedShareOwnerUser,
                            });
                        }
                    }
                }
                if (customShareByRecord.length > 0) {
                    this.butLoading = true;
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "mutation{customsharelist(o:" + JSON.stringify(customShareByRecord) +
                        "){id errmsg}}"
                    ).then(res => {
                        this.butLoading = false;
                        if (res.errcode == "0") {
                            this.selectedShareCustomType = null;
                            this.selectedShareCategoryUser = null;
                            MessageTip.successmsg('共享成功');
                        } else {
                            MessageTip.warnmsg('共享失败');
                        }
                        this.closeShare();
                    });
                }
            },
            closeTransfer() {
                this.displayTransfer = false;
            },
            saveTransfer() {
                console.log(this.selectedTransferUser);
                var customTransfer = [];
                if (this.selectedRow && this.selectedRow.length > 0 && this.selectedTransferUser) {
                    for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                        customTransfer.push({
                            "id": this.selectedRow[k1]["id"],
                            "ownerid": this.selectedTransferUser
                        });
                    }
                }
                this.butLoading = true;
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{crmcustomlist(o:" + JSON.stringify(customTransfer) +
                    "){id errmsg}}"
                ).then(res => {
                    this.butLoading = false;
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.selectedTransferUser = null;
                        this.loadData(1);
                        MessageTip.successmsg('转移成功');
                    } else {
                        MessageTip.warnmsg('转移失败');
                    }
                    this.closeTransfer();
                });
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            freshList() {
                this.displayMaximizable = false;
                this.butLoading = false;
                this.loadData(1);
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            onImportUpload(event) {
                console.log(event);
                var responseObj = JSON.parse(event.xhr.responseText);
                if (responseObj.code == '0') {
                    MessageTip.successmsg('文件上传成功');
                    console.log(unescape(Base64.decode(responseObj.data)));
                    var retObj = JSON.parse(unescape(Base64.decode(responseObj.data)));
                    requests.plat_requests.post(
                        '../calf/plat/ExcelTemplateImport?appkey=' + sessionStorage.getItem(
                            "appkey"), {
                            templateid: this.importTemplateId,
                            filepatch: retObj.patch,
                        }
                    ).then(res => {
                        this.importDisplay = false;
                        console.log('res:');
                        console.log(res);
                        if (res.errcode != "0") {
                            MessageTip.warnmsg('数据导入失败');
                        } else {
                            this.excelDatas = JSON.parse(unescape(Base64.decode(res.data)));
                            this.startImport = true;
                            this.uploadExcelData();
                        }
                    });
                } else {
                    this.importDisplay = false;
                    MessageTip.errmsg('文件上传失败:' + responseObj.err);
                }
            },
            beforeImportUpload(event) {
                event.xhr.open('post', this.uploadurl);
                event.xhr.setRequestHeader('Access-Token', sessionStorage.getItem('usertoken'));
                event.formData.append('uptype', '1');
                event.formData.append('attachttype', '1');
            },
            closeImport() {
                this.importProgress = '';
                this.startImport = false;
                this.importProgressValue = 0;
                this.excelDatas = [];
                this.importDisplay = false;
            },
            getTemplateFilePatch() {
                if (this.importTemplateId == '178228300017733') {
                    location.href = '/calf/plat/file/exceltemplate/客户导入模板.xlsx?appkey=' + sessionStorage.getItem(
                        "appkey");
                    return;
                }
                if (this.importTemplateId == '178710117163077') {
                    location.href = '/calf/plat/file/exceltemplate/客户联系人导入模板.xlsx?appkey=' + sessionStorage.getItem(
                        "appkey");
                    return;
                }
                MessageTip.warnmsg('请选择导入类型');
                return;
            },
            uploadExcelData() {
                if (this.excelDatas.length > 0) {
                    this.importProgress = '共' + this.excelDatas[0]['arrayData'].length + '条数据,已导入0条,还剩余' + this
                        .excelDatas[0]['arrayData'].length + '条';
                    setTimeout(() => {
                        this.continueUploadExcelData(this.excelDatas[0]['mutationName'], this.excelDatas[0][
                            'arrayData'
                        ], 0, 0, 0);
                    }, 10);
                }
            },
            continueUploadExcelData(mutationName, alldatas, importIndex, failureIndex, currentIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress = '共' + alldatas.length + '条数据,正导入第' + (currentIndex + 1) +
                        '条数据,已成功导入' + importIndex + '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    orderObj['id'] = '-1';
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        'import{' + mutationName + '(o:' + JSON.stringify(orderObj) +
                        '){id errmsg}}'
                    ).then(res => {
                        if (res.errcode == "0") {
                            importIndex = importIndex + 1;
                        } else {
                            MessageTip.warnmsg('导入第' + (currentIndex + 1) + '条数据失败');
                            failureIndex = failureIndex + 1;
                        }
                        currentIndex = currentIndex + 1;
                        this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                            .toFixed(0);
                        console.log('importProgressValue:' + this.importProgressValue);
                        console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                        if (this.startImport) {
                            setTimeout(() => {
                                this.continueUploadExcelData(mutationName, alldatas, importIndex,
                                    failureIndex,
                                    currentIndex);
                            }, 10);
                        }
                    });
                } else {
                    this.importProgress = '共' + alldatas.length + '条数据,已成功导入' + importIndex +
                        '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex - failureIndex) + '条';
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
            CalfMultiSelect,
            CalfInputDropdown,
            CalfDropdown,
        }
    }
</script>